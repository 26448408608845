.userButton {
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 0px 10px;
    transition: background 0.4s ease;
    position: relative;
}

.userButton:hover {
    background: rgba(0, 0, 0, 0.09);
    transition: background 0.6s ease;
}

.userProfileIcon {
    width: 35px;
    height: 35px;
    object-fit: contain;
}

.userName {
    margin: 0 8px;
    line-height: 17px;
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.down-option-list {
    position: absolute;
    right: 49px;
    margin-top: 42px;
    width: 150px;
    border-radius: 4px;
    background-color: white;
    padding: 4px;
    z-index: 100;
    display: none;
    border: solid 0.2px #ccc;
}

.down-option-list.show {
    display: block;
}

.down-option-list > .option {
    cursor: pointer;
    padding: 5px;
    color: #000;
    width: 100%;
    display: flex;
    justify-content: center;
    border-radius: 4px;
}

.down-option-list > .option:hover {
    background: rgba(0, 0, 0, 0.1);
    transition: background 0.3s ease;
}