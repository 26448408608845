.dataTableMargin {
    margin-right: 3%;
}

.searchTableInputBox {
    height: 35px;
    width: 300px;
    border-radius: 5px;
    border: 1px solid #dfdfdf;
    padding-left: 13px;
}

.searchTableButton {
    width: 200px;
    border-radius: 5px;
    height: 35px;
    background-color: #1273de;
}

#dataTable {
  box-shadow: 0 4px 10px 0 rgb(0 0 0 / 10%);
  border-radius: 5px;
}

#dataTable header {
    margin: 0px;
    display: flex !important;
    justify-content: center !important;
    align-items: flex-end !important;
}

.editTag {
    color: #007bff !important;
    cursor: pointer !important;
}
.deleteTag {
    color:  red !important;
    cursor: pointer !important;
}
.rdt_TableHeadRow {
    font-weight: 800;
    font-size: 14px;
    text-transform: uppercase;
}

.addNewProductButton {
    width: max-content;
    padding: 10px;
    background-color: #1273de;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-weight: 800;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.addNewProductButtonUI .addNewProductButton {
  margin-left: 15px;
  margin-right: 15px;
}

.addNewProductButtonUI {
  display: flex;
  justify-content: space-between;
  padding-bottom: 2%;
  background: #fff;
  padding-top: 2%;
}

.bottomUI {
  display: flex;
  justify-content: center;
  width: 100%;
  border-top: 1px solid #dfdfdf;
  bottom: 0;
  margin-bottom: 0px;
  height: 84px;
  align-items: center;
}
.flex-col {
    flex-direction: column;
}

.extraPadding {
  padding: 50px !important;
  padding-top: 30px !important;
}

.lds-dual-ring {
    display: inline-block;
    top:4px;
    margin-left: 15px;
    margin-top: 5px;
    width: 30px;
    height: 30px;
  }
  .lds-dual-ring:after {
    content: ' ';
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 3px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .refreshIcon {
    margin-right: 20px;
    cursor: pointer;
  }

  .fullWidth {
      width: 100% !important; 
  }

  .rdt_TableCell div {
    width: 100% !important;    
    margin-bottom: 10px !important;
    margin-top: 10px !important;
  }

  .addNewInvoiceIcon {
    margin-top: 3%;
    margin-bottom: 3%;
    text-align: center;
    align-items: center;
    font-size: 30px;
    cursor: pointer;
    color: green;
    margin-right: 10px;
  }

  #center {
    justify-content: center;
    width: 50%;
  }