.login-and-registration {
    display: flex;
    flex-direction: 'row';
    min-height: 100vh;
    height: 100%;
}

.login-and-registration-left {
    background: rgb(13, 37, 65);
    background: linear-gradient(0deg, rgba(13, 37, 65, 1) 0%, rgba(6, 127, 212, 1) 73%);
    text-align: center;
    position: fixed;
    width: 29.4%;
    height: 100%;
}

.login-and-registration-left .login-logo {
    margin-top: 5vh;
    width: 40%;
}

.login-and-registration-right {
    background-color: #f5f7f9;
    position: absolute;
    width: 100%;
    min-height: 100%;
    height: max-content;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-form {
    background-color: #ffffff;
    padding: 54px 88px 54px 89px;
    border-radius: 10px;
    padding-bottom: 54.5px;
    padding-top: 6%;
    width: 100%;
}

.login-form select,.login-form input {
    width: 99%;
}

.login-form input[type="checkbox"] {
    width: max-content;
}

.text-login-Welcome-header {

    font-size: 22px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: 0.14px;
    text-align: left;
    color: #2c2c2c;
}

.login-text-container {
    margin-top: 20px;
}

.new-here {
    margin-top: 5px;
    font-weight: normal;

    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
}

.new-here>span {
    color: #095691;
    margin-left: 1px;
    cursor: pointer;
}

.TAndC-wrapper {
    margin-top: 20px;
}

.common-text-TAndC {

    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.02px;
    text-align: left;
    color: #879cb3;
}

.hr-line-TAndC {
    border: 0;
    border-top: 1px solid #879cb3;
    margin-top: 20px;
}

.common-link-TAndC {
    display: block;
    color: #095691;
    text-decoration: underline;
}

.field-with-rows {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.square-input-wrapper {
    display: flex;
    margin: 5px
}

.finish-form-wrapper {
    text-align: center;
}

.form-filled-text {
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: 0.14px;
    text-align: center;
    color: #2c2c2c;
    margin-top: 20px;
}

.text-apply-another {
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #422AFB;
    margin-top: 10px;
    text-align: center;
}

.termsAndConditions-checkbox-wrapper,
.sign-in-button-wrapper {
    margin-top: 30px;
}
.Error {
    font-size: small;
    color: #e4001c;
    margin-bottom: 10px;
    margin-top: 10px;
    font-weight: 600;
  }

 .darkSquareBox {
    background: #067fd4 !important;
    color: #ffff !important;
 }

 .input-square {
    width: 100%;
    border: 1px solid #067fd4;
    color: #656565;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    margin: 5px;
    font-size: 12px;
    cursor: pointer;
}

.textAreaBox {
    padding: 20px;
    font-size: 13px;
    width: 100%;
    height: 70px;
    border: 0.4px solid #CCCCCC;
    box-sizing: border-box;
    border-radius: 8px;
    background: aliceblue;
}

.paddingCEO {
    padding-top: 20px;
}

select {
    cursor: pointer;
}

.registration-progress {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-bottom: 30px;
    border-radius: 50px;
}

.reg-stage {
    border: solid 1px #ccc;
    width: 100%;
    height: 5px;
}

.registration-progress :first-child {
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
}

.registration-progress :last-child {
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
}

.reg-stage  > span {
    display: none;
}

.reg-stage-active {
    background-color: #422AFB;
}

.reg-back-btn {
    cursor: pointer;
    width: max-content;
    padding: 5px 10px;
    border-radius: 4px;
    font-weight: bold;
    color: #656565;
}

.reg-back-btn::before {
    content: "\00AB";
    font-size: 20px;
    margin-right: 5px;
}

.reg-back-btn:active {
    background-color: #DFEEFA;
    color: #2261aa;
}

#autoComplete .MuiInput-underline:before {
    border-bottom: none;
}
#autoComplete .MuiFormControl-fullWidth {
    height: 32px;
    padding: 0 0 0 20px;
    border-radius: 5px;
    border: solid 0.4px #6e6e6e;
    width: 100%;

}
#autoComplete  .MuiAutocomplete-input {
    font-size: 12px;
    padding-right: 0px;
    padding-top: 8px;
}