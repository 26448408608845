* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Nunito Sans",sans-serif;
  /* font-family: "Montserrat", sans-serif; */
}

.CODE_ONE {
  color: #2f2f2f;
}

.CODE_TWO {
  color: #5F5F5F;
}

.FONT_16 {
  font-size: 16px !important;
}

.FONT_18 {
  font-size: 18px !important;
}

.impAsterisk {
  color: red;
}

.cursor-pointer {
  cursor: pointer;
}

.big-card-wrapper {
  margin-top: 40px;
}

.big-dashboard-card {
  background-color: #fff;
  /* box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);*/
  transition: 0.3s;
  width: 100%;
  padding: 32px 32px 32px 51px;
  border-radius: 4px;
  border: var(--PRIMARY_BORDER_WIDTH) solid var(--PRIMARY_BORDER_COLOR)
}


.justify-space-between {
  justify-content: space-between;
}

.text-center {
  text-align: center;
}

.common-field-margin-top {
  margin-top: 30px;
}

.common-field-margin-bottom {
  margin-bottom: 30px;
}

.common-button-margin-top {
  margin-top: 50px;
}

.finish-form-wrapper .button-primary {
  margin-top: 13px;
  width: 40%;
}

.heading1 {
  font-size: 20px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #2f2f2f;
}

.heading2 {
  font-size: 16px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: #2f2f2f;
}

.mainHeader {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  background-color: #1273de;
  position: relative;
}

.HeaderLeft {
  width: 152px;
  display: flex;
  justify-content: flex-end;
  height: 41px;
}

.HeaderCenter {
  margin: 0 25px;
  display: flex;
  flex-grow: 1;
}

.HeaderCenter button {
  border: none;
  padding: 10px;
  margin-right: 10px;
  align-self: center;
  color: white;
  border-radius: 10px;
  background: linear-gradient(45deg, #00000020, #1a4a81);
  min-width: 100px;
  box-shadow: 1px 1px 1px 1px #1a1a1a60;
}

.HeaderCenter button:first-child {
  margin-left: 5px;
}

.HeaderRight {
  width: 200px;
  display: flex;
  justify-content: flex-start;
}

.pointer {
  cursor: pointer;
}

.logoutWrapper {
  display: flex;
  align-items: center;
  /* border-left: 2px solid #ffffff; */
}

.logoutWrapper>span {
  margin-left: 15px;
  font-size: 14px;
  font-weight: bold;
}

.loaderGif {
  height: 250px;
}

.textCenter {
  text-align: center;
}

.lds-dual-ring {
  display: inline-block;
  top: 4px;
  margin-left: 21px;
  margin-top: -2px;
  width: 15px;
  height: 15px;
}
.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 18px;
  height: 14px;
  border-radius: 50%;
  border: 3px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.cursorPointer {
  cursor: pointer;
}

.button {
  cursor: pointer;
}

.companyDetailsCardTop {
  position: relative;
}

.applicationControlRow {
  height: 100%;
  display: flex;
  flex-flow: row;
  position: relative;
  float: left;
  margin-right: 15px;
  margin-top: 10px;
}

.applicationControlContextMenu {
  width: 200px;
  background: #fff;
  border-radius: 4px;
}

.contextMenuButton {
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid #ccc;
  margin: 0 25px;
  font-weight: 700;
  color: #1a4a81;
  cursor: pointer;
}

.rejectWrapper, .unRejectWrapper {
  right: 250px;
}

.saveForFutureWrapper, .unSaveForFutureWrapper {
  right: 320px
}

.subDecisionModalSelect {
  font-size: 14px;
  margin-top: 10px;
}

.subDecisionModalSelect select {
  outline: none;
  height: 32px;
  border: solid 1px #ccccccaa;
  border-radius: 4px;
  background: #eee;
  margin-left: 5px;
}

.imageHeight {
  object-fit: cover;
  width: 100px;
}

.commonSubmitButton {
  width: 180px;
  margin: 2px;
  border: none;
  font-size: 13px;
  border-radius: 3px;
  font-weight: 700;
  cursor: pointer;
  height: 40px;
  padding-right: 31px;
  padding-left: 31px;
  margin-right: 11px;
}

.blueBgColor {
  background: #422AFB;
  color: #ffffff;
}

.whiteBgColor {
  background: #ffffff;
  color: #484848;
  border: 1px solid;
}

.ebAuDD {
  top: 9% !important;
  bottom:unset !important
}

.text-area {
  resize: none;
}

.textarea, textarea {
  resize: none;
}

.textarea:focus {
  resize: none;
}

.crystallize-growl {
  z-index: 999;
  top: 170px!important;
  position: fixed!important;
  height: 0px;
}

.forgotForm {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  height: 100%;
}

.forgotPasswordContent h5 {
  font-size:15px;
}

.forgotImage {
  width: 100%;
}

.forgotInputBox {
 display: block;
 padding: .375rem .75rem;
 line-height: 1.5;
 width: 80%;
 font-size: 12px;
 color: #495057;
 background-color: aliceblue;
 background-clip: padding-box;
 height: 44px;
 border-radius: 3px;
 border: solid 0.4px #dfdfdf;
 margin-bottom: 2%;
 margin-top: 1%;
}

.forgotFlex {
 display: flex;
 justify-content: center;
 flex-direction: column;
 text-align: center;
}

.forgotFlexCenter {
 display: flex;
 justify-content: center;
}

.forgotFlexPad {
 width: 80%;
 text-align: left;
 padding-left: 0px;
 font-size: 13px;
 font-weight: 800;
}

.comButton {
  width: 184px;
  height: 42px;
  margin-right: 20px;
  border-radius: 4px;
  padding: 7px;
  padding-bottom: 10px;
  color: #fff;
  background-color: #422AFB;
  padding-top: 9px !important;
  display: inline-block;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  font-size: 14px;
  cursor: pointer;
}

.mainContainer {
  overflow: auto;
  height: 100vh;
  background: #f5f7f9;
  display: block;
}



.forgotPasswordContent {
  width: 50%;
  margin: auto;
  background: #ffff;
}

.forgotPasswordContent h1 {
  color: #484848;
  font-weight: 800;
}

.greyCommentColor {
  color: #9b9b9b;
}

.model-file-upload-header {
  padding: 20px;
  padding-bottom: 40px;
  left: 0;
  top: 0px;
  right: 0;
  height: 45px;
  z-index: 17;
  background: #fefefe;
  bottom: 64px;
  position: fixed;
  border-bottom: 1px solid #d6d6d6;
  display: flex;
  justify-content: space-between;
}

.model-file-upload-flex {
  display: flex;
  justify-content: space-between;
}

.model-file-upload-flex-two {
  width:80%;
  padding: 20px;
}

.fp__sidebar {
  color: #505e5f;
  position: fixed;
  width: 230px;
  top: 61px;
  bottom: 64px;
  z-index: 16;
  overflow-y: auto;
  padding-top: 20px;
  background: #eee;
  border-right: 1px solid #d6d6d6;
}

.model-file-upload-height {
  height: 100%;
}

.fp__drag-and-drop {
  position: fixed;
  top: 107px;
  bottom: 63px;
  left: 230px;
  transition: all ease-in-out .4s;
  background: #ecedec;
  border: 2px solid transparent;
  background-size: 40px 40px;
  padding: 20px;
  text-align: center;
  align-items: center;
  display: flex;
  right: 0px;
}

.content__header {
  min-height: 46px;
  margin-bottom: 20px;
  top: 62px;
  right: 0;
  left: 229px;
  z-index: 15;
  background: #eee;
  position: fixed;
  padding: 20px;
}

.content__header__title {
  color: #809395;
  font-size: 14px;
  font-weight: 300;
  display: flex;
  justify-content: space-between;
}

.fp__drag-and-drop-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
} 

.google-connect-button {
  background: #2d75c6;
  border-color: #2d75c6;
  color: #fff;
  height: 34px;
  text-align: center;
  white-space: nowrap;
  line-height: 1.42857143;
  border: 1px solid transparent;
  box-shadow: inset 0 1px 0 rgb(255 255 255 / 20%), 0 2px 0 rgb(0 0 0 / 5%);
  padding: 6px 25px;
  border-radius: 17px;
  margin-bottom: 25px;
}

.model-small-text {
  font-size: 75%;
  color: #777;
}
.fp__close {
  top: 104px;
  right: 108px;
  width: 35px;
  height: 35px;
  z-index: 20;
  cursor: pointer;
}

.active-side-bar-color {
  display: flex;
  align-items: center;
  color: #505e5f;
  background: #fff;
  min-height: 26px;
  padding-top: 5px;
  padding-right: 20px;
  padding-bottom: 5px;
  padding-left: 50px;
  text-decoration: none;
  line-height: 1.3;
  font-weight: 600;
  font-size: 13px;
  cursor: pointer;
  margin-bottom: 6px;
}

.not-active-side-bar-color {
    display: flex;
    align-items: center;
    min-height: 26px;
    padding-top: 5px;
    padding-right: 20px;
    padding-bottom: 5px;
    padding-left: 50px;
    color: #505e5f;
    text-decoration: none;
    line-height: 1.3;
    font-size: 13px;
    cursor: pointer;
    margin-bottom: 6px;
}

.model-icon-margin {
  margin-right: 10px;
  width: 24px;
  height: 24px;
}

.input-box-overlay {
  padding: 40px 100px;
  border: 2px dashed #ccc;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.drag-file-text {
  font-size: 24px;
  color: #8b9da0;
  font-weight: 300;
}

.linkBox {
    width: 30%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    margin-bottom: 10px;
    margin-top: 10px;
    border-radius: 6px;
}

.listOfFiles {
  display: flex;
  flex-direction: column;
  min-height: 500px;
  overflow: auto;
  height: 100%;
  width: 100%;
}

.single-file {
  background: #fff;
  margin-bottom: 2%;
  cursor: pointer;
  border-bottom: 1px solid #d6d6d6;
  text-align: left;
  padding: 10px;
  border-radius: 12px;
}

.fileName-margin {
  height: 30px;
  display: flex;
  align-items: center;
}
.common-fileName {
  height: 16px;
  width: 16px;
}

.common-file-name-value {
  font-size: 15px;
  font-weight: bold;
  text-transform: capitalize;
  padding-left: 20px;
}

.selected-file {
  background-color: #e8f0fe !important;
  color: #1967d2 !important;
}

.file-upload-button {
  position: fixed;
  bottom: 0;
  border: 2px solid transparent;
  background-size: 40px 40px;
  text-align: center;
  align-items: center;
  display: flex;
  right: 145px;
  height: 36px;
}

.file-upload-button-margin {
  margin-right: 15px;
}
.no-records-found {
  font-size: 13px;
  margin-top: 8%;
  color: #809395;
  font-weight: bold;
}

.google-logout {
  height: 25px;
  width:  25px;
  cursor: pointer;
}

.textRight {
  text-align: right;
}

.accordionHeader {
  width: 100%;
  justify-content: space-between;
  padding: 10px 50px;
  border-radius: 4px;
  box-shadow: 4px 0 10px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px rgba(0, 0, 0, 16%);
  background-color: #ffffff;
}

.accordionCollapsed {
  padding: 10px 0;
}

.accordionHeaderPrimaryText {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: #313536;
}

.accordionHeaderSecondaryText {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #b1b6b8;
}

.headerSizeIconStyle {
  width: 18px;
  height: 18px;
  object-fit: contain;
  margin-right: 20px;
  margin-left: 20px;
}

.headerCorrectnessIconStyle {
  width: 30px;
  height: 30px;
  margin-right: 20px;
  margin-left: 20px;
  object-fit: contain;
}

#accordionHeaderContainer {
  padding: 0px;
  height: 50px;
  margin-bottom: 10px;
}

#accordionContainer {
  box-shadow: 4px 0 10px 0 rgb(0 0 0 / 0%);
  margin-bottom: 10px;
  border: none !important;
}

#accordionDetailsContainer {
  padding: 0px;
  padding-left: 80px;
  margin-bottom: 7px;
  align-items: center;
}

.accordionDetails {
  background-color: #ffffff;
  justify-content: space-between;
}

.summaryCollapseWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 4px;
  box-shadow: 0 0px 10px 2px rgb(0 0 0 / 4%);
  border: solid 1px rgb(0 0 0 / 15%);
  padding: 20px 35px;
}

.accordionDetailsPrimaryText {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  color: #60ba76;
  margin-left: 20px;
}

.detailsCorrectnessIconStyle {
  width: 24px;
  height: 24px;
  margin-right: 20px;
  margin-left: 20px;
  object-fit: contain;
}

.summaryFormContainer {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  background: #fff;
  padding: 50px;
  border: var(--PRIMARY_BORDER_WIDTH) solid var(--PRIMARY_BORDER_COLOR);
  border-top: none;
}

.FONT13 {
  font-size: 13px;
}

.companyLogoHeight {
  height: 150px !important;
  width: 150px !important;
  background: #1a4a81 !important;
}

.flexInviteButtonSpace {
  display: flex;
  justify-content: space-between
}

.bulkInviteButton {
  padding-right: 60px;
}

.bulkInviteButton button, .sensitivityAnalysisFooter button {
    width: 200px !important;
    margin-left: 20px;
}

.sensitivityAnalysisFooter button.whiteBg {
  color: #422AFB;
  border: solid 1px #422AFB;
  font-weight: bold;
}

.sensitivityAnalysisFooter {
  display: flex;
  justify-content: flex-end;
  padding: 20px 10px;
}

.notificationLayout {
  border-radius: 12px;
  background-color: #fff;
  height: max-content;
  padding: 50px;
  overflow: scroll;
}

.notificationHeader {
  padding: 20px;
  margin-top: 8px;
  padding-left: 35px;
  font-size: 17px;
  z-index: 17;
  background: #fefefe;
  border-bottom: 1px solid #d6d6d6;
  display: flex;
  justify-content: space-between;
}

.subjectInput {
    height: 33px;
    width: 100%;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    padding-left: 10px;
}

.summaryAccordionOuterCard {
  border-radius: 4px;
  box-shadow: 0 4px 10px 0 rgb(0 0 0 / 10%);
  border: var(--PRIMARY_BORDER_WIDTH) solid var(--PRIMARY_BORDER_COLOR);
  padding: 40px 10px;
}

.button-medium-size {
  width: 18% !important;
  margin-bottom: 5%;
}

.flex-end {
  justify-content: flex-end;
}


.loader-top-margin {
  margin-top: 5%;
  margin-left: 35%;
}

.comparisonCompanyEntry {
  display: flex;
  flex-flow: column nowrap;
  min-width: 25%;
  flex-grow: 1;
  align-items: center;
  text-align: center;
  padding: 20px 0;
  padding-bottom: 10px;
  /* border-right: 1px solid #ccc; */
  border-bottom: 1px solid #ccc;

  position: -webkit-sticky;
  position: sticky;
  top: 0;
  align-self: flex-start;

  background: #fff;
}

.comparisonCompanyEntry span {
  font-weight: 700;
  margin-top: 15px;
}

.comparisonCompanyDetails {
  height: 90%;
  display: flex;
  overflow: auto;
  border-bottom: 1px solid #ccc;
}

.columnNameList {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  width: 25%;
  color: #1a4a81;
}

.spacerEntry {
  user-select: none;
  border-bottom: 1px solid #ccc;
  color: #FAFAFA;
  width: 100%;
  flex-grow: 0;
}

.spacerEntry .spacerLogo {
  width: 70px !important;
  height: 70px !important;
  opacity: 0;
}

.keyCardName {
  background: #fafafa;
  padding-left: 15%!important;
}

.keyCardName, .columnEntry {
  padding: 15px 0;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.columnEntry:nth-child(odd) {
  background: rgb(243, 243, 243);
}

.comparisonCompanyColumn {
  min-width: 25%;
  flex-grow: 1;
  text-align: center;
}

.closeModalBtn {
  color: #1a1a1a;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: 700;
  position: absolute;
  top: 5px;
  right: 15px;
}

.fOusnf {
  background-color: #1a4a81;
  color: rgb(255, 255, 255);
  height: 48px;
  cursor: move;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
}

.fOusnf span{
  position: absolute;
  right: 32px;
  cursor: pointer;
}

.fOusnf-content{ 
  padding-left: 20px!important;
  padding-right: 20px!important;
}

.fOusnf-content h2{
  margin-bottom: 2%;
  margin-top: 3%;
  font-size: 19px;
  font-weight: 800;
}

.fOusnf-content-msg {
  font-weight: 400;
  font-size: 14px;
  color: #33475b;
  line-height: 24px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.intl-tel-input-one input {
  border-radius: 3px;
  border: 1px solid #cbd6e2;
  font-size: 16px;
  transition: all .15s ease-out;
  background-color: #f5f8fa;
  color: #33475b;
  display: block;
  height: 40px;
  line-height: 22px;
}

.mobileError {
  color: #f2545b!important;
  font-size: 12px;
  line-height: 18px;
}
.crXxyY {
  display: flex;
  background-color: rgb(245, 248, 250);
  border-top: 1px solid rgb(255, 255, 255);
  position: fixed;
  bottom: 0;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
}

.crXxyY button {
  width: 200px;
}

.cancel-button {
  background-color: #fff !important;
  border-color: #ff7a59 !important;
  color: #ff7a53 !important;
  border: 1px solid !important;
}

.crXxyY button a{
  text-decoration: none;
  color: #ffff;
}

.disabled {
  pointer-events: none;
  background-color: #eaf0f6!important;
  border-color: #eaf0f6!important;
  color: #b0c1d4!important;
  cursor: not-allowed;
  border: none;
}

.label-checkbox {
  margin-top: 5%;
  margin-left: 3%;
}
.label-checkbox-margin {
  margin-top: 5%;
  margin-left: 3%;
}
.label-checkbox-margin label{
  padding-left: 10px;
  font-size: 14px;
}

.mainTabPriority {
  align-items: center;
  display: flex;
  margin-left: 10%;
}

.mainTabPriorityIcon {
  margin-left: 9px;
  cursor: pointer;
  font-size: 13px;
}

.mainTabPriorityPopup {
  margin-top: 7% !important;
}
.mainTabPriorityPopupInput {
  width: 50% !important;
  height: 44px !important;
}
.mainTabPriorityPopupSpace {
  justify-content: space-between;
}

.mainTabPriorityPopupFooter{
  border-top: 1px solid #dfdfdf;
  padding-top: 20px;
}

.mainTabPriorityModel {
  margin: 20px;
}

.model-file-upload-header-1 {
  padding: 20px;
  padding-bottom: 40px;
  left: 0;
  top: 0px;
  right: 0;
  height: 45px;
  z-index: 17;
  background: #fefefe;
  bottom: 64px;
  border-bottom: 1px solid #d6d6d6;
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */

  .flexLoginBox {
    flex-direction: column;
  }

  .flexLoginHalfWidthBox.left {
    position: unset !important;
  }

  .flexLoginHalfWidthBox {
    width: 100% !important;
  }
  .flexLoginHalfWidthBox.right {
    position: unset !important;
    transform: none !important;
  }
  .login-form-layout {
    padding: 5px !important;
    padding-top: 6% !important;
  }
  .forgotForm { 
    flex-direction: column;
  }
  .forgotPasswordContent {
    width: 90%;
  }
  .login-form {
    padding: 30px !important;
    padding-top: 6% !important;
  }

}